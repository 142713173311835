import React from 'react'
import SanityImage from 'gatsby-plugin-sanity-image-alt'
import { useSanityImageShape } from '../helpers/useSanityImageShape'

const Image = image => {
  let width = 600
  let height = 320

  if (typeof image.asset.metadata.dimensions !== 'undefined') {
    width = image.asset.metadata.dimensions.width
    height = image.asset.metadata.dimensions.height
  }

  const imageShaped = useSanityImageShape(image)

  return (
    <SanityImage
      {...imageShaped}
      className="object-cover w-full h-full my-4"
      width={width}
      height={height}
      alt={`${image.alt}`}
    />
  )
}

export default Image
