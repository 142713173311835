import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import React from 'react'

import ArrowDropdown from '../svg/arrow-dropdown.svg'

const StyledLink = ({ label, link, rel, target }) => {
  const internal = /^\/(?!\/)/.test(link)

  let relValue
  if (rel && target) relValue = 'nofollow noopener noreferrer'
  if (rel && !target) relValue = 'nofollow'

  if (link && internal) {
    if (!link.endsWith('/')) link = `${link}/`

    return (
      <Link
        to={link}
        className="inline-flex items-center text-sm mt-6"
        rel={relValue}
        target={target ? '_blank' : '_self'}
      >
        {label}
        <ArrowDropdown className="mb-2px ml-2 transform -rotate-90" />
      </Link>
    )
  }

  if (link && !internal) {
    return (
      <a
        href={link}
        className="inline-flex items-center text-sm"
        rel={relValue}
        target={target ? '_blank' : ''}
      >
        {label}
        <ArrowDropdown className="mb-2px ml-2 transform -rotate-90" />
      </a>
    )
  }
}

StyledLink.propTypes = {
  type: PropTypes.string,
  rel: PropTypes.bool,
  target: PropTypes.bool,
}

StyledLink.defaultProps = {
  rel: false,
  target: false,
}

export default StyledLink
