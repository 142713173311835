import React from 'react'
import PropTypes from 'prop-types'
// import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'

import serializers from './serializers'

const PortableText = ({
  blocks,
  isLight,
  isFooter,
  isHeader,
  isHeaderHome,
  isCard,
  isBasic,
  isColumn,
}) => (
  <div
    className={`prose
      ${isBasic ? ' md:prose-lg' : ''} 
      ${isLight ? 'prose-light md:prose-light-lg' : ''} 
      ${isFooter ? 'prose-footer md:prose-footer-lg' : ''} 
      ${isHeader ? 'prose-header md:prose-header-lg' : ''} 
      ${
        isHeaderHome ? 'prose-header prose-header-home md:prose-header-lg' : ''
      } 
      ${isCard ? 'prose-card md:prose-card-md' : ''}
      ${isColumn ? 'prose-column md:prose-column-lg' : ''} 
    `}
  >
    <BasePortableText blocks={blocks} serializers={serializers} />
  </div>
)

export default PortableText

PortableText.propTypes = {
  blocks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLight: PropTypes.bool,
  isFooter: PropTypes.bool,
  isHeader: PropTypes.bool,
  isCard: PropTypes.bool,
  isBasic: PropTypes.bool,
  isColumn: PropTypes.bool,
}

PortableText.defaultProps = {
  blocks: {},
  isLight: false,
  isFooter: false,
  isHeader: false,
  isCard: false,
  isBasic: false,
  isColumn: false,
}
