import React from 'react'
import ReactPlayer from 'react-player'

const setLength = (custom, aspect, i) => {
  let customAspect
  if (
    custom !== undefined &&
    custom.width !== undefined &&
    custom.height !== undefined
  )
    customAspect = `${custom.width}:${custom.height}`
  const aspectRatio = customAspect || aspect || '16:9'
  return aspectRatio.split(':')[i]
}

const VideoEmbed = ({ url, aspect, custom }) => {
  const aspectWidth = setLength(custom, aspect, 0)
  const aspectHeight = setLength(custom, aspect, 1)
  const aspectRatio = `${(aspectHeight / aspectWidth) * 100}%`

  return (
    <div
      className="relative"
      style={{
        paddingTop: aspectRatio,
      }}
    >
      <ReactPlayer
        className="absolute inset-0"
        width="100%"
        height="100%"
        url={url}
        controls
      />
    </div>
  )
}

export default VideoEmbed
