import React from 'react'
import BaseBlockContent from '@sanity/block-content-to-react'

import { Link } from 'gatsby'
import Button from './Button'
import StyledLink from './StyledLink'
import FAQs from './FAQs'
import FormattedList from './FormattedList'
import Image from './Image'
import PopupLink from './PopupLink'
import FormLink from './FormLink'
import ProfitRoomLink from './ProfitRoomLink'
import VideoEmbed from './VideoEmbed'
import Profile from './Profile'

const serializers = {
  types: {
    block: props => {
      if (props.node.style === 'large') {
        return (
          <p>
            <span className="md:text-lg">{props.children}</span>
          </p>
        )
      }
      if (props.node.style === 'h2') {
        return (
          <h2 className="text-xl font-bold">{props.children}</h2>
        )
      }
      if (props.node.style === 'h3') {
        return (
          <h3 className="text-lg font-bold">{props.children}</h3>
        )
      }
      return <p className="mb-4">{ props.children }</p>
    },
    undefined: () => '',
    styledLink: ({ node }) => <StyledLink {...node} />,
    faqs: ({ node }) => <FAQs faqs={node.faqs} />,
    formattedList: ({ node }) => <FormattedList listItems={node.listItems} />,
    hr: () => <hr />,
    mainImage: ({ node }) => <Image {...node} />,
    formLink: ({ node }) => <FormLink {...node} type="buttonGreen" />,
    profitRoomLink: ({ node }) => <ProfitRoomLink {...node} />,
    popupLink: ({ node }) => <PopupLink {...node} />,
    profiles: ({ node }) => <Profile {...node} />,
    videoEmbed: ({ node }) => (
      <VideoEmbed url={node.url} aspect={node.aspect} custom={node.custom} />
    ),
    link: ({ node }) => {
      return (
      <Button
        to={node.href}
        type={node.type}
        colour={node.colour}
        rel={node.noFollow}
        target={node.newTab}
      >
        {node.label}
      </Button>
    )},
  },
  marks: {
    link: ({ children, mark }) => {
      
      const internal = /^\/(?!\/)/.test(mark.href)

      if (internal) {
        return <Link to={mark.href}>{children}</Link>
      }
      return (
        <a href={mark.href} target="_blank" rel="nofollow noopener noreferrer">
          {children}
        </a>
      )
    },
  },
}

export default serializers
